import { useSpring, animated } from "react-spring";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

import "./preguntasFrecuentes.css";
import "swiper/css/effect-creative";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export default function Preguntas() {
  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  });

  return (
    <main className="preguntas">
      <Helmet>
        <title>Preguntas Frecuentes - Black Wallet</title>
        <meta name="description" content="Si tienes preguntas, nosotros te damos las respuestas" />
      </Helmet>

      <animated.div style={fadeOut}>
        <div className="preguntas-frecuentes-container">
          {vistaMobile === false ? (
            <div className="bloque-separate">
              {/* se puede volar el bloque cuando quieras  */}
            </div>
          ) : null}

          {vistaMobile === false ? (
            <Desktop />
          ) : (
            <Mobile />
          )}
          <div className="bloque-separate" />
        </div>
      </animated.div>
    </main>
  );
}
