import { Helmet } from "react-helmet";
import "./TerminosCondiciones.css";

const TerminosCondiciones = () => {
    return (
        <main className="terminos-condiciones">
            <Helmet>
                <title>Términos y Condiciones - Black Wallet</title>
                <meta name="description" content="Términos y condiciones de Black Wallet" />
            </Helmet>

            <section className="terminos-content">
                <div className="container">
                    <h1>TERMINOS Y CONDICIONES DE USO</h1>
                    <p>
                        Estos términos y condiciones constituyen un contrato entre los usuarios de la plataforma (en
                        adelante, “Usuario”) y Conexión de Altura S.A. (RAMPY), a los fines del uso de las cuentas de
                        pago provistas por RAMPY.
                    </p>

                    <h2>REGISTRO</h2>
                    <p>
                        Todo Usuario que desee utilizar los Servicios debe registrarse y aceptar estos Términos y
                        Condiciones de Uso. Para ello debe completar el formulario de registro en todos sus campos con
                        datos válidos e información personal exacta, precisa y verdadera (Datos Personales).
                        El Usuario asume el compromiso de actualizar los Datos Personales conforme resulte necesario.
                        RAMPY no se responsabiliza por la certeza de los Datos Personales provistos por sus Usuarios.
                        Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad,
                        vigencia y autenticidad de los Datos Personales ingresados. Toda la información y los Datos
                        Personales ingresados por el Usuario tienen carácter de declaración jurada.
                        RAMPY se reserva el derecho de solicitar comprobantes y/o información adicional a efectos de
                        corroborar la información entregada por un Usuario en materia de Datos Personales, así como
                        de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser
                        confirmados.
                    </p>
                    <p>
                        Asimismo el Usuario presta consentimiento para que el intermediario (cualquier tercero
                        cliente de RAMPY que brinde una plataforma al Usuario) comparta con RAMPY, los datos
                        personales que RAMPY requiera para dar cumplimiento a la Comunicación A 7328, la Resolución
                        UIF 76/2019 y las restantes que luego dictare el BCRA o la Unidad de Información Financiera que
                        le fueran aplicable.
                    </p>
                    <p>
                        Se deja expresa constancia que el Usuario autoriza al Sujeto Obligado a compartir toda la
                        información y documentación contenida en su legajo relativa a su identificación y el origen y
                        licitud de los fondos, con otros sujetos obligados consignados en el artículo 20 de la Ley N°
                        25.246 o aquellas que la modifiquen, complementen o sustituyan.
                    </p>
                    <p>
                        RAMPY se reserva el derecho de rechazar una solicitud de registro o de cancelar o suspender,
                        temporal o definitivamente una Cuenta (conforme es definida a continuación), en caso de
                        detectar incongruencias o inconsistencias en la información provista por un Usuario o en caso
                        de detectar actividades sospechosas, sin que tal decisión genere para el Usuario derechos de
                        indemnización o resarcimiento.
                    </p>

                    <h2>CUENTA DE USUARIO</h2>
                    <p>
                        Al registrarse, el Usuario solicitará la apertura de una cuenta en la plataforma, para lo cual
                        RAMPY generará una Clave Virtual Uniforme (CVU) vinculada a la Cuenta del Usuario. El Usuario
                        accederá a su Cuenta mediante la contraseña o clave personal elegida. El Usuario se obliga a
                        mantener la confidencialidad de las mismas. En virtud de ello, el Usuario será el único y exclusivo
                        responsable por todas las operaciones efectuadas en su Cuenta.
                    </p>
                    <ul>
                        <li>
                            <strong>Utilización del servicio por cuenta propia.</strong> La Cuenta es personal, única e intransferible,
                            quedando prohibida su venta, cesión o transferencia. El Usuario no podrá permitir ni
                            autorizar el uso de su Cuenta por terceras personas. Solamente se permite utilizar los
                            servicios en nombre propio y no en representación y/o por cuenta de terceros.
                        </li>
                        <li>
                            <strong>Objeto.</strong> A través de su Cuenta el Usuario tendrá la posibilidad de recibir y enviar dinero
                            acreditando fondos en otras CVU o CBU, a través de las herramientas habilitadas en la
                            Plataforma o aplicación móvil. El Usuario podrá disponer de los fondos acreditados en
                            forma inmediata de acuerdo con lo establecido en estos Términos y Condiciones. Se
                            aclara que el Usuario acuerda, acepta y autoriza recibir fondos que oportunamente
                            envíen otros Usuarios a su Cuenta y que se le debite cualquier cargo que resulte
                            aplicable.
                        </li>
                        <li>
                            <strong>RAMPY es ajeno a la obligación que dio origen a la operación.</strong> RAMPY no será
                            responsable ni verificará las causas, importe o cualquier otra circunstancia relativa a
                            dicha operación, así como la capacidad para contratar de los Usuarios y la veracidad de
                            los datos personales por ellos ingresados. El Usuario será el único y exclusivo
                            responsable por todas las operaciones efectuadas en su Cuenta. El Usuario se
                            compromete a notificar a RAMPY en forma inmediata y por medio idóneo y fehaciente,
                            de cualquier uso no autorizado de su Cuenta, así como del ingreso o de intentos de
                            ingreso por terceros no autorizados a la misma. RAMPY no responderá por el uso
                            indebido de la clave de ingreso.
                        </li>
                        <li>
                            <strong>Declaraciones del Usuario. Usos prohibidos. Indisponibilidad de fondos.</strong> El Usuario
                            manifiesta que en el uso del Sistema no infringirá ninguna ley aplicable, ni lo utilizará por
                            un servicio, venta o transmisión que está prohibida por la ley o estos Términos y
                            Condiciones Generales y/o cualquier tipo de actividad que pueda ser considerada
                            fraudulenta o ilegal o sospechosa de serlo. El Usuario no utilizará los servicios de RAMPY
                            para transmitir material que constituya un delito o bien que pueda dar lugar, directa o
                            indirectamente a responsabilidades civiles o que infrinjan los presentes Términos y
                            Condiciones Generales. Si RAMPY considera que hay una sospecha o indicio de la
                            utilización de la plataforma para alguna actividad prohibida por la ley o los presentes
                            Términos y Condiciones Generales, podrá rechazar, cancelar o suspender cualquier
                            procesamiento de pago, y/o bloquear temporalmente el acceso y uso de una Cuenta y/o
                            la utilización o disponibilidad de funcionalidades y/o cancelar definitivamente una
                            Cuenta. En tal caso, el Usuario responderá por los daños y perjuicios que pueda haber
                            ocasionado a RAMPY, sus funcionarios, empleados, directores, agentes, y/o empleados,
                            a cuyos efectos RAMPY se reserva el derecho de iniciar las acciones judiciales o
                            extrajudiciales que estime pertinentes. El Usuario será exclusiva e ilimitadamente
                            responsable por los perjuicios que su conducta pueda causar a RAMPY o a los restantes
                            Usuarios de los servicios de RAMPY.
                        </li>
                    </ul>
                    <p>
                        Por razones de seguridad, los fondos que se acrediten en la Cuenta y/o que estén en
                        proceso de ser acreditados (sin importar el medio de pago por el que se hubiera
                        efectuado la transacción) podrán permanecer indeterminadamente indisponibles
                        cuando, a exclusivo criterio de RAMPY, existan fuertes sospechas de ilegalidades,
                        fraude, excesiva cantidad de operaciones o transacciones que puedan ser fraudulentas,
                        o cualquier otro acto contrario a los presentes Términos y Condiciones y/o sospechas de
                        violación de preceptos legales por los cuales RAMPY deba responder.
                    </p>
                    <ul>
                        <li>
                            <strong>RAMPY no es entidad financiera. Limitación de responsabilidad por los fondos.</strong> De
                            acuerdo a la normativa vigente del Banco Central de la República Argentina, RAMPY es
                            un Proveedor de Servicios de Pago y en tal carácter se limita a ofrecer servicios de pago
                            y no se encuentra autorizado por el BCRA para operar como una entidad financiera en
                            los términos de la Ley Nº 21.526. Los fondos depositados en las Cuentas Virtuales
                            RAMPY no constituyen depósitos en una entidad financiera y no cuentan con ninguna
                            de las garantías que tales depósitos puedan gozar de acuerdo con las normas aplicables
                            en materia de depósitos en entidades financieras. RAMPY mantendrá los fondos de la
                            Cuenta Virtual en cuentas bancarias de bancos del sistema financiero argentino de su
                            elección.
                        </li>
                        <li>
                            <strong>Intereses.</strong> Los fondos acreditados en la Cuenta Virtual no generan intereses y el Usuario
                            podrá disponer libremente de ellos para realizar las operaciones descriptas en el
                            presente, ello una vez que se encuentren disponibles en su cuenta y conforme los plazos
                            y mecanismos y reglas determinados por RAMPY.
                        </li>
                    </ul>

                    <h2>CONFIDENCIALIDAD</h2>
                    <p>
                        RAMPY no venderá, alquilará ni negociará con otras empresas la información personal de los
                        Usuarios. Estos datos serán utilizados para prestar el Servicio de cuentas de Pago. Sin embargo,
                        RAMPY podrá compartir dicha información con proveedores de servicios de valor agregado que
                        se integren dentro del sitio o mediante links a otros sitios de Internet, para atender necesidades
                        de los Usuarios relacionadas con los servicios que suministra RAMPY. Las imágenes recopiladas
                        se utilizan para verificación de identidad y no se almacenan en el dispositivo, sólo se guarda en
                        servidores para posteriores validaciones y/o auditorías. RAMPY no responderá por perjuicios que
                        se puedan derivar de la violación de dichas medidas por parte de terceros que utilicen las redes
                        públicas o Internet para acceder a dicha información o en los casos en que haya habido culpa o
                        negligencia del Usuario.
                    </p>

                    <h2>DATOS PERSONALES</h2>
                    <p>
                        El Usuario declara conocer el acuerdo arribado entre la Entidad a la cual se encuentra
                        vinculado/asociado y RAMPY, en virtud del cual ha solicitado acceder a la Plataforma. En
                        consecuencia, brinda su consentimiento expreso a fin de que dicha Entidad brinde sus datos
                        personales y los que tuviere en sus registros a RAMPY y autoriza expresamente a la Entidad y a
                        RAMPY a efectuar el intercambio de datos e información necesarios para llevar a cabo la
                        disposición de fondos mediante la plataforma, una vez aceptados los presentes Términos y
                        Condiciones de Uso. Con relación a los datos identificatorios recabados y registrados por RAMPY
                        y por la Entidad, de conformidad con la normativa de la Ley N° 25.326 de Protección de Datos
                        Personales y en particular lo dispuesto por el Artículo 6, presta expresa conformidad para que
                        los mismos sean utilizados para la consideración de productos y/o servicios que pueda ofrecer
                        en el futuro, como así también para el procesamiento de las respectivas operaciones. Disp. 10 /
                        2008 DNPDP. “<i>El titular de los datos personales tiene la facultad de ejercer el derecho de acceso
                            a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un
                            interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326</i>”.
                        “<i>La DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES Órgano de Control de la Ley
                            Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con
                            relación al incumplimiento de las normas sobre protección de datos personales</i>”. A
                        sus efectos se transcribe Art. 14 Ley 25.326 (Derecho de acceso): “<i>1. El titular de los datos, previa
                            acreditación de su identidad, tiene derecho a solicitar y obtener información de sus datos
                            personales incluidos en los bancos de datos públicos, o privados destinados a proveer informes.
                            2. El responsable o usuario debe proporcionar la información solicitada dentro de los diez días
                            corridos de haber sido intimado fehacientemente. Vencido el plazo sin que se satisfaga el pedido,
                            o si evacuado el informe, éste se estimara insuficiente, quedará expedita la acción de protección
                            de los datos personales o de hábeas data prevista en esta ley. 3. El derecho de acceso a que se
                            refiere este artículo sólo puede ser ejercido en forma gratuita a intervalos no inferiores a seis
                            meses, salvo que se acredite un interés legítimo al efecto. 4. El ejercicio del derecho al cual se
                            refiere este artículo en el caso de datos de personas fallecidas le corresponderá a sus sucesores
                            universales.</i>” RAMPY hará sus mejores esfuerzos para mantener la confidencialidad y seguridad
                        de que trata esta sección, pero no responderá por perjuicios que se puedan derivar de la
                        violación de dichas medidas por parte de terceros que utilicen las redes públicas o Internet para
                        acceder a dicha información o en los casos en que haya habido culpa o negligencia del Usuario.
                    </p>

                    <h2>CONFRONTACIÓN DE DATOS PERSONALES CON RENAPER – CONSENTIMIENTO INFORMADO</h2>
                    <p>
                        Por medio de la presente el Usuario, en su carácter de titular de sus datos personales, presta su
                        conformidad para que RAMPY confronte sus datos personales con la base de datos del RENAPER.
                        El presente consentimiento para el tratamiento de datos personales alcanza a los incluidos en el
                        Documento Nacional de Identidad (incluyendo datos biométricos de huella dactilar y de
                        reconocimiento facial) en confronte con lo que informa el web service del REGISTRO NACIONAL
                        DE LAS PERSONAS.
                    </p>
                    <p>
                        En concordancia con lo mencionado en el párrafo anterior, se deja constancia que el titular de
                        los datos podrá ejercer los derechos de acceso, rectificación y supresión de sus datos en
                        cualquier momento y a su sola solicitud ante el RENAPER. Asimismo, en cumplimiento de la
                        Resolución AAIP Nº 14/2018, se hace saber que la AGENCIA DE ACCESO A LA INFORMACIÓN
                        PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender
                        las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por
                        incumplimiento de las normas vigentes en materia de protección de datos personales.
                    </p>

                    <h2>LIMITACIÓN DE RESPONSABILIDAD POR EL SERVICIO</h2>
                    <p>
                        RAMPY no garantiza el acceso y uso continuado o ininterrumpido de la plataforma. El sistema
                        puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet en
                        los links o herramientas o por cualquier otra circunstancia ajena a RAMPY, no siendo responsable
                        RAMPY de tales perjuicios resultantes de las mencionadas dificultades, así como por cualquier
                        otra clase de daños, incluyendo daños indirectos, especiales o consecuentes que surjan o
                        experimenten los Usuarios.
                    </p>

                    <h2>PROPIEDAD INTELECTUAL</h2>
                    <p>
                        Todos los derechos intelectuales e industriales, sobre el Sitio, Códigos, desarrollo, software,
                        hardware, dominio, logos, diseños, información, etc. son de propiedad de RAMPY o de la
                        plataforma. En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los
                        mismos.
                    </p>

                    <h2>MODIFICACIÓN EN LOS TÉRMINOS Y CONDICIONES</h2>
                    <p>
                        RAMPY podrá modificar en cualquier momento estos Términos y Condiciones de Uso, a cuyo fin
                        notificará los cambios al Usuario publicando una versión actualizada de los mismos en la
                        plataforma con expresión de la fecha de la última modificación. Todos los términos modificados
                        entrarán en vigor a los 10 (diez) días de su publicación. Dentro de los 10 (diez) días siguientes a
                        la publicación de las modificaciones introducidas, el Usuario deberá comunicar por e-mail si no
                        acepta las mismas; en ese caso quedará disuelto el vínculo contractual. Vencido este plazo, se
                        considerará que el Usuario acepta los nuevos términos y el contrato continuará vinculando a
                        ambas partes.
                    </p>

                    <h2>CESIÓN</h2>
                    <p>
                        El Usuario no podrá ceder, transferir, delegar o disponer de los derechos u obligaciones derivados
                        del presente Contrato, total o parcialmente. RAMPY podrá ceder, transferir, delegar o disponer
                        de los derechos u obligaciones derivados del presente o de su posición contractual, total o
                        parcialmente, notificando dicho al acto al Usuario mediante un aviso en el sitio, para lo cual el
                        Usuario otorga su consentimiento de manera previa para la realización de dichas acciones.
                    </p>

                    <h2>TERMINACIÓN</h2>
                    <p>
                        RAMPY y el Usuario podrán en cualquier oportunidad de vigencia del presente contrato
                        terminarlo sin expresión de causa alguna, lo que implicará el cierre de la Cuenta. A los efectos
                        de ejercer esta facultad, es necesario que la parte que pretende la terminación del contrato no
                        adeude a la otra ni a terceros involucrados en las operaciones el cumplimiento de alguna
                        obligación.
                    </p>
                    <p>
                        Cualquiera de las partes podrá, en caso de incumplimiento de la contraparte al presente contrato
                        o a la legislación aplicable en la materia, dar por terminada la prestación de los Servicios sin
                        ningún tipo de aviso, reservándose el derecho de reclamar los daños y perjuicios que tal
                        incumplimiento haya causado. Se deja expresa constancia y el Usuario declara conocer que la
                        vigencia de la CVU está ligada a la vigencia del contrato entre la entidad a la cual se encuentra
                        vinculada y RAMPY. En caso de que dicho contrato fuese rescindido o finalizara por cualquier
                        causa, las CVU creadas en el marco del mismo serán cerradas observando las previsiones
                        mencionadas en los párrafos precedentes.
                    </p>

                    <h2>DOCUMENTACIÓN</h2>
                    <p>
                        El Usuario opta expresamente por que la documentación e información relacionada con el
                        Contrato provista tenga soporte electrónico. El Usuario podrá hacer copias, incluso en soporte
                        físico, de la documentación e información provista por RAMPY relativa al Contrato. El Usuario
                        autoriza en forma irrevocable a RAMPY y/o a quien RAMPY designe, a grabar las operaciones del
                        Usuario relativas a los servicios prestados, y a utilizar dichas grabaciones como medio probatorio
                        ante autoridades administrativas y/o judiciales.
                    </p>

                    <h2>PREVENCIÓN DE LAVADO DE DINERO Y FINANCIAMIENTO DEL TERRORISMO</h2>
                    <p>
                        El usuario toma conocimiento y acepta que RAMPY se encuentra facultado para requerirle toda
                        información necesaria para dar cumplimiento a las normas legales relacionadas con la
                        Prevención de Lavado de Dinero y Financiamiento del Terrorismo (Ley 25.246 y modificatorias,
                        Res. 30/2017, 76/2019 y vinculadas de la Unidad de Información Financiera -UIF-), que permita
                        la identificación del usuario, así como el origen, legitimidad y licitud de los fondos con los que
                        opera. En tal sentido, el usuario se obliga a suministrar a primer requerimiento, los elementos
                        informativos que a estos efectos le fuesen solicitados. Asimismo, el usuario brinda su
                        consentimiento expreso en los términos dispuestos por artículo 21 de la Resolución 76/2019 y
                        modificatorias de la UIF para que RAMPY pueda compartir la información y/o documentos y/o
                        instrumentos que integran el legajo del Cliente que este haya presentado o presente en el futuro
                        ante RAMPY con otras personas jurídicas que integren junto con RAMPY un mismo grupo
                        económico; para que estos, como sujetos obligados en los términos dispuestos Ley 25.246,
                        modificatorias y complementarias, puedan realizar la identificación del Cliente, acreditar su
                        situación patrimonial, contable e impositiva y/o para que compartan toda otra información o
                        documental que permita establecer y conocer el origen, legitimidad y licitud de los fondos con
                        los que opera el usuario y cumplir con las obligaciones que les corresponden como sujeto
                        obligado. Por último, el usuario declara conocer y aceptar que ha sido debidamente informado
                        por RAMPY respecto a que las normas citadas, establecen que los sujetos obligados en el
                        tratamiento de los legajos que puedan compartir, deben asegurar el debido cumplimiento de
                        confidencialidad.
                    </p>

                    <h2>NOTIFICACIONES</h2>
                    <p>
                        Serán válidas todas las notificaciones realizadas a los Usuarios a la dirección de correo
                        electrónico principal registrada por éstos (domicilio especial electrónico), mediante la aplicación
                        móvil y/o a la Cuenta del Usuario.
                    </p>

                    <h2>DOMICILIOS</h2>
                    <p>
                        Se fija como domicilio de RAMPY Leónidas Aguirre 455 de la ciudad de Mendoza, Provincia de
                        Mendoza, Argentina. El domicilio constituido por los Usuarios es el domicilio registrado ante
                        RAMPY.
                    </p>

                    <h2>JURISDICCIÓN Y LEY APLICABLE</h2>
                    <p>
                        Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la Argentina.
                        Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación,
                        alcance o cumplimiento, será sometida a los tribunales de la ciudad de Mendoza, Provincia de
                        Mendoza.
                    </p>
                </div>
            </section>
        </main>
    );
}

export default TerminosCondiciones;